<template>
  <div id="bd">
    <div id="ban-in">
      <div class="ban-bg"></div>
      <div class="banner-box wp">
        <h3>新闻动态</h3>
      </div>
    </div>

    <div class="wp">
      <div class="tit-i">
        <h3>新闻动态</h3>
      </div>
      <div id="newsAllList" style="width: 100%; height: auto">
        <ul class="ul-list">
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="goDetail(item)"
          >
            <div class="pad">
              <div class="pic">
                <a><img style="height: 186.53px" :src="item.imageUrl" /></a>
              </div>
              <div class="bor">
                <div class="txt">
                  <div class="title">
                    <span
                      ><em
                        >{{ item.createTime.substring(5, 7) }}/{{
                          item.createTime.substring(8, 10)
                        }}</em
                      >{{ item.createTime.substring(0, 4) }}</span
                    >
                    <h3>
                      <a>{{ item.title }}</a>
                    </h3>
                  </div>
                  <a class="message"
                    ><p>
                      {{ item.extraInfo.remark }}
                    </p></a
                  >
                </div>
              </div>
            </div>
          </li>
          <div style="clear: both"></div>
        </ul>
      </div>
      <div style="text-align: center; margin-bottom: 105px; margin-top: 20px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="page"
          :page-size="8"
          :total="total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Http from '@/utils/http';

export default {
  name: 'yjyNews',

  data() {
    return {
      page: 1,
      total: 0,
      list: [],
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: '/yjyNewsDetail',
        query: { id: item.id },
      });
    },
    getList() {
      Http({
        method: 'get',
        url: 'https://rh.2hrh.com/Rhinfo/newsInfo/list',
        data: {
          typeId: 'b2efdab97978477ca97256f6c1a64c64',
          page: this.page,
          limit: 8,
        },
      }).then((res) => {
        // console.log(res);
        this.list = res.data.records;
        this.total = Number(res.data.total);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#bd {
  //padding-top: 90px;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  padding-top: 90px;
  background-size: 100% 100%;
  height: 500px;
  background: #008cd6;
  // url(../../../assets/front/about/aboutbanner.png) center center
  //   no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../../assets/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.banner-box {
  height: 100%;
  padding-top: 150px;
  animation-name: fadeInUp;
  animation: fadeInUp 0.5s linear;
  h3 {
    font-size: 46px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  h5 {
    margin-top: 18px;
    font-size: 20px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 8px;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.ul-list {
  margin: 0 -15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.ul-list li {
  width: 23%;
  margin-bottom: 30px;
  cursor: pointer;
}
.ul-list li .pic img {
  width: 100%;
  display: block;
  transform: perspective(800px) rotateY(0deg);
  transition: all 1s ease 0s;
  -moz-transform: perspective(800px) rotateY(0deg);
  -moz-transition: all 1s ease 0s;
  -webkit-transform: perspective(800px) rotateY(0deg);
  -webkit-transition: all 1s ease 0s;
  -o-transform: perspective(800px) rotateY(0deg);
  -o-transition: all 1s ease 0s;
  -ms-transform: perspective(800px) rotateY(0deg);
  -ms-transition: all 1s ease 0s;
  zoom: 1;
}
.ul-list li:hover img {
  transform: perspective(800px) rotateY(0deg);
  -moz-transform: perspective(800px) rotateY(0deg);
  -webkit-transform: perspective(800px) rotateY(0deg);
  -o-transform: perspective(800px) rotateY(0deg);
  -ms-transform: perspective(800px) rotateY(0deg);
}
.ul-list li {
  padding: 0 8px;
}
.ul-list li .pad {
  border: 1px solid #e2e3e8;
}
.ul-list li:hover .pad {
  //border-color: #1071b5;
  border: none;
  box-shadow: 0px 0px 12px rgba(153, 153, 153, 0.6);
}
.ul-list li .txt {
  padding: 20px 14px;
  line-height: 20px;
  color: #666;
}
.ul-list li .more {
  height: 42px;
  line-height: 42px;
  border-top: 1px dotted #e2e3e8;
  background-color: #f0f2f5;
  padding: 0 14px;
  display: none;
}
.ul-list li .more a {
  color: #1071b5;
}
.ul-list li .more a:hover {
  text-decoration: underline;
}
.ul-list li .title {
  height: 55px;
  margin-bottom: 20px;
}
.message {
  font-size: 12px;
}
.ul-list li .title span {
  float: left;
  border: 1px solid #e2e3e8;
  background-color: #f0f2f5;
  display: inline-block;
  text-align: center;
  padding: 6px 5px;
  margin-right: 15px;
}
.ul-list li .title span em {
  display: block;
  font-size: 16px;
}
.ul-list li .title h3 {
  border-bottom: 1px solid #e2e3e8;
  overflow: hidden;
  height: 58px;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.ul-list li .txt {
  max-height: 135px;
  min-height: 135px;
}
.n,
em {
  font-weight: normal;
  font-style: normal;
}

a {
  color: #333;
}
a:hover {
  color: #1071b5;
}
</style>
